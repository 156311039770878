<template>
  <div>
    <b-card>
      <h4>
          Información de usuario
      </h4>
      <b-form
        class=""
        @submit.prevent
      >
      <validation-observer ref="userValidation">
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Nombre(s)" label-for="name">
            <validation-provider #default="{ errors }" name="name" rules="required">
              <b-form-input id="name" v-model="user.firstName" :state="errors.length > 0 ? false:null" name="name" placeholder="Nombre(s)" maxlength="254"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Apellido(s)" label-for="name">
            <validation-provider #default="{ errors }" name="lastName" rules="required">
              <b-form-input id="name" v-model="user.lastName" :state="errors.length > 0 ? false:null" name="name" placeholder="Apellido(s)" maxlength="254"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Teléfono" label-for="phone">
            <validation-provider #default="{ errors }" name="phone" rules="required|digits:10">
              <cleave class="form-control" id="phone" v-model="user.phone" :state="errors.length > 0 ? false:null" name="phone" :options="options.phoneMask" placeholder="Teléfono"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Correo Electrónico" label-for="email">
            <validation-provider #default="{ errors }" name="email" rules="required|email">
              <b-form-input id="email" v-model="user.email" :state="errors.length > 0 ? false:null" name="email" placeholder="Correo Electrónico"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label-for="password">
            <template #label>
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <span>Contraseña (de un solo uso) &nbsp;</span>
                    <button
                      type="button"
                      class="text-primary btn btn-link btn-sm p-0"
                      @click="suggestPassword"
                    > Sugerir contraseña </button>
                </div>
                <button
                  type="button"
                  class="text-primary btn btn-link btn-sm p-0"
                  @click="visiblePassword = !visiblePassword">
                  {{ visiblePassword ? 'Ocultar' : 'Mostrar' }}
                </button>
              </div>
            </template>
            <validation-provider #default="{ errors }" name="password" rules="required|password">
              <b-form-input id="password" v-model="user.password" :state="errors.length > 0 ? false:null" name="password" placeholder="Contraseña" :type="visiblePassword ? 'text' : 'password'"/>
              <small class="text-danger">{{$t( errors[0] )}}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label-for="confirmPassword">
            <template #label>
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <span>Confirmar Contraseña &nbsp;</span>
                </div>
                <button
                  type="button"
                  class="text-primary btn btn-link btn-sm p-0"
                  @click="visibleConfirmPassword = !visibleConfirmPassword">
                  {{ visibleConfirmPassword ? 'Ocultar' : 'Mostrar' }}
                </button>
              </div>
            </template>
            <validation-provider #default="{ errors }" name="confirmPassword" rules="required|confirmed:password">
              <b-form-input id="confirmPassword" v-model="user.confirmPassword" :state="errors.length > 0 ? false:null" name="confirmPassword" placeholder="Confirmar Contraseña" :type="visibleConfirmPassword ? 'text' : 'password'"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="RPE / RTT" label-for="rpe">
            <validation-provider #default="{ errors }" name="RPE / RTT" rules="required">
              <b-form-input id="rpe" v-model="user.rpe" :state="errors.length > 0 ? false:null" name="rpe" placeholder="RPE / RTT"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="NIP" label-for="NIP">
            <validation-provider #default="{ errors }" name="NIP" rules="required">
              <b-form-input id="NIP" v-model="user.NIP" :state="errors.length > 0 ? false:null" autocomplete="off" name="NIP" placeholder="NIP" maxlength="4" type="password"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Tipo de usuario" label-for="userType">
            <validation-provider #default="{ errors }" name="tipo de usuario" rules="required">
              <b-form-select name="userType" :options="userType" v-model="user.type" :state="errors.length > 0 ? false:null" :reduce="v => v.value" value-field="value" text-field="label" :clearable="true"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" class="mb-md-0 mb-2" v-if="user.type !== 'C'" >
          <b-form-group label="Zona" label-for="cfeZone" >
            <v-select input-id="cfeZone" :options="listZones" v-model="selectedZone" label="name" :reduce="v => v.uuid" multiple>
              <template #no-options>
                No tienes zonas disponibles en esta división. Registra al menos una para continuar.
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" offset-md="6" class="d-flex justify-content-end">
          <b-col cols="6">
            <b-button type="submit" variant="primarySpore" block @click="validationForm">Guardar</b-button>
            </b-col>
          &nbsp;
          <b-col cols="6">
            <b-button type="reset" block variant="danger" @click="cancel()">Cancelar</b-button>
          </b-col>
        </b-col>
      </b-row>
      </validation-observer>
      </b-form>
    </b-card>
  </div>
</template>
<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormSelect
} from 'bootstrap-vue'
import { required } from '@validations'
import request from '@/libs/request/index'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import vSelect from 'vue-select'
/* eslint-disable quotes */
import { hashString } from "@/libs/session/index"
import store from '@/store'
import { generatePassword } from '@core/utils/utils'

const divisionUuid = store.state.session.AppActiveUser().division.uuid

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    Cleave,
    vSelect
  },
  data () {
    return {
      listZones: [],
      selectedZone: [],
      user: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        password: '',
        confirmPassword: '',
        rpe: '',
        NIP: '',
        type: ''
      },
      required,
      visiblePassword: false,
      visibleConfirmPassword: false,
      userType: [
        { value: 'C', label: 'Administrador CFE' },
        { value: 'S', label: 'Supervisor' },
        { value: 'I', label: 'Instalador' }
      ],
      options: {
        phoneMask: {
          blocks: [3, 3, 4],
          uppercase: true
        }
      }

    }
  },
  methods: {
    cancel () {
      this.user = {}
      this.$router.push('/users')
    },
    async getZones () {
      const params = {
        url: '/zones',
        method: 'GET',
        params: { divisionUuid }
      }
      const { data } = await request(params)
      this.listZones = data
    },
    async add () {
      if (this.user.type !== 'C' && this.selectedZone.length === 0) {
        this.$swal({
          title: 'Favor de seleccionar una zona',
          text: '',
          timer: 5000,
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-warning'
          },
          buttonsStyling: false
        })
      } else {
        const params = {
          url: 'users/',
          method: 'POST',
          params: {
            email: this.user.email,
            password: this.user.password,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            phone: this.user.phone,
            type: this.user.type,
            rpe: this.user.rpe,
            nip: hashString(this.user.NIP),
            divisionUuid,
            zones: this.selectedZone
          }
        }
        await request(params).then(response => {
          this.user = {}
          this.$router.push('/users')
        }).catch(err => {
          const errMsg = err?.response?.data?.error || err?.response?.data?.msg
          if (errMsg) {
            this.$swal({
              title: 'Error',
              text: errMsg,
              timer: 5000,
              icon: 'warning',
              customClass: {
                confirmButton: 'btn btn-warning'
              },
              buttonsStyling: false
            })
          }
        })
      }
    },
    validationForm () {
      this.$refs.userValidation.validate().then(success => {
        if (success) {
          this.add()
        }
      })
    },
    suggestPassword () {
      const password = generatePassword()
      this.user.password = password
      this.visiblePassword = true
    }
  },
  created () {
    this.getZones()
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
