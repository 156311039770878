var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('h4',[_vm._v(" Información de usuario ")]),_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"userValidation"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Nombre(s)","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"name":"name","placeholder":"Nombre(s)","maxlength":"254"},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Apellido(s)","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"name":"name","placeholder":"Apellido(s)","maxlength":"254"},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Teléfono","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"phone","rules":"required|digits:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"phone","state":errors.length > 0 ? false:null,"name":"phone","options":_vm.options.phoneMask,"placeholder":"Teléfono"},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Correo Electrónico","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"name":"email","placeholder":"Correo Electrónico"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label-for":"password"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_c('span',[_vm._v("Contraseña (de un solo uso)  ")]),_c('button',{staticClass:"text-primary btn btn-link btn-sm p-0",attrs:{"type":"button"},on:{"click":_vm.suggestPassword}},[_vm._v(" Sugerir contraseña ")])]),_c('button',{staticClass:"text-primary btn btn-link btn-sm p-0",attrs:{"type":"button"},on:{"click":function($event){_vm.visiblePassword = !_vm.visiblePassword}}},[_vm._v(" "+_vm._s(_vm.visiblePassword ? 'Ocultar' : 'Mostrar')+" ")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"password","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password","state":errors.length > 0 ? false:null,"name":"password","placeholder":"Contraseña","type":_vm.visiblePassword ? 'text' : 'password'},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t( errors[0] )))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label-for":"confirmPassword"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_c('span',[_vm._v("Confirmar Contraseña  ")])]),_c('button',{staticClass:"text-primary btn btn-link btn-sm p-0",attrs:{"type":"button"},on:{"click":function($event){_vm.visibleConfirmPassword = !_vm.visibleConfirmPassword}}},[_vm._v(" "+_vm._s(_vm.visibleConfirmPassword ? 'Ocultar' : 'Mostrar')+" ")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"confirmPassword","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"confirmPassword","state":errors.length > 0 ? false:null,"name":"confirmPassword","placeholder":"Confirmar Contraseña","type":_vm.visibleConfirmPassword ? 'text' : 'password'},model:{value:(_vm.user.confirmPassword),callback:function ($$v) {_vm.$set(_vm.user, "confirmPassword", $$v)},expression:"user.confirmPassword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"RPE / RTT","label-for":"rpe"}},[_c('validation-provider',{attrs:{"name":"RPE / RTT","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rpe","state":errors.length > 0 ? false:null,"name":"rpe","placeholder":"RPE / RTT"},model:{value:(_vm.user.rpe),callback:function ($$v) {_vm.$set(_vm.user, "rpe", $$v)},expression:"user.rpe"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"NIP","label-for":"NIP"}},[_c('validation-provider',{attrs:{"name":"NIP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"NIP","state":errors.length > 0 ? false:null,"autocomplete":"off","name":"NIP","placeholder":"NIP","maxlength":"4","type":"password"},model:{value:(_vm.user.NIP),callback:function ($$v) {_vm.$set(_vm.user, "NIP", $$v)},expression:"user.NIP"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Tipo de usuario","label-for":"userType"}},[_c('validation-provider',{attrs:{"name":"tipo de usuario","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"name":"userType","options":_vm.userType,"state":errors.length > 0 ? false:null,"reduce":function (v) { return v.value; },"value-field":"value","text-field":"label","clearable":true},model:{value:(_vm.user.type),callback:function ($$v) {_vm.$set(_vm.user, "type", $$v)},expression:"user.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.user.type !== 'C')?_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Zona","label-for":"cfeZone"}},[_c('v-select',{attrs:{"input-id":"cfeZone","options":_vm.listZones,"label":"name","reduce":function (v) { return v.uuid; },"multiple":""},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" No tienes zonas disponibles en esta división. Registra al menos una para continuar. ")]},proxy:true}],null,false,748504223),model:{value:(_vm.selectedZone),callback:function ($$v) {_vm.selectedZone=$$v},expression:"selectedZone"}})],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"12","md":"6","offset-md":"6"}},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{attrs:{"type":"submit","variant":"primarySpore","block":""},on:{"click":_vm.validationForm}},[_vm._v("Guardar")])],1),_vm._v("   "),_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{attrs:{"type":"reset","block":"","variant":"danger"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Cancelar")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }